<template>
  <div
    :class="'item-title paddingRight' + `${marginLeft ? ' marginLeft' : ' '}`"
  >
    <span class="left">{{ title }}</span>
    <span class="right">
      <el-button
        v-if="goBackButton"
        size="mini"
        icon="el-icon-back"
        @click="goBack"
        >返回</el-button
      >
      <el-button
        v-if="cancelButton"
        size="mini"
        icon="el-icon-back"
        @click="cancel"
        >取消</el-button
      >
      <el-button
        v-if="saveButton || saveDraft"
        class="ty-primary-button"
        size="mini"
        icon="el-icon-folder-checked"
        @click="save"
        >{{ saveDraft ? "保存草稿" : "保存" }}</el-button
      >
      <el-button
        v-if="finishButton"
        class="ty-primary-button"
        size="mini"
        icon="el-icon-circle-check"
        @click="finish"
        >完成</el-button
      >
    </span>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    title: {
      type: String,
      default: "",
    },
    marginLeft: {
      type: Boolean,
      default: false,
    },
    cancelButton: {
      type: Boolean,
      default: false,
    },
    saveButton: {
      type: Boolean,
      default: false,
    },
    finishButton: {
      type: Boolean,
      default: false,
    },
    goBackButton: {
      type: Boolean,
      default: false,
    },
    saveDraft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$emit("save");
    },
    finish() {
      this.$emit("finish");
    },
    goBack() {
      this.$emit("goBack");
    },
  },
};
</script>

<style lang="scss" scoped>
.item-title {
  position: relative;
  padding: 12px 0 12px 16px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  background: #ffffff;
  align-items: center;
}

.marginLeft {
  margin-left: 20px !important;
}

.paddingRight {
  padding-right: 20px !important;
}

.item-title::before {
  content: "";
  height: 18px;
  border-left: 3px solid #363f47;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.item-title::after {
  content: "";
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0px;
}

.right {
  .ty-primary-button {
    border: 1px solid #2861e2;
    color: #2861e2;
  }
}
</style>
