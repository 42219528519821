<template>
  <div class="checkQuestion">
    <div class="search-area">
      <el-input
        v-model="searchForm.name"
        style="width: 160px"
        placeholder="请输入问题ID/内容"
        size="mini"
        prefix-icon="el-icon-search"
        class="item"
      ></el-input>

      <div class="item">
        <span>类型：</span>
        <el-select v-model="searchForm.type" size="mini" placeholder="请选择问题题型">
          <el-option
            v-for="item in options2"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>

      <div class="item">
        <span>题型：</span>
        <el-select v-model="searchForm.questionType" placeholder="请选择问题类型" size="mini">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <el-button size="mini" class="button-item" type="primary" @click="search">查询</el-button>
      <el-button size="mini" class="button-item" @click="reset">重置</el-button>
    </div>

    <el-table
      ref="dataTable"
      border
      :data="tableList"
      max-height="400px"
      @select="onSelectData"
      @select-all="onSelectDataAll"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="problemNo" label="问题ID"></el-table-column>
      <el-table-column prop="content" label="问题内容"></el-table-column>
      <el-table-column prop="options" label="选项">
        <template slot-scope="scope">
          {{ scope.row.options ? scope.row.options : '/' }}
        </template>
      </el-table-column>

      <el-table-column label="问题类型">
        <template slot-scope="scope">
          {{
            scope.row.problemType == '0'
              ? '自定义'
              : scope.row.problemType == '1'
              ? '数据字典'
              : scope.row.problemType == '2'
              ? '标签库'
              : scope.row.problemType == '3'
              ? '拍摄产品'
              : '拍摄人'
          }}
        </template>
      </el-table-column>

      <el-table-column label="问题题型">
        <template slot-scope="scope">
          {{
            scope.row.type == '0'
              ? '单选题'
              : scope.row.type == '1'
              ? '多选题'
              : scope.row.type == '2'
              ? '日期编辑题'
              : scope.row.type == '3'
              ? '填空题'
              : '图片编辑题'
          }}
        </template>
      </el-table-column>

      <el-table-column label="关联信息">
        <template slot-scope="scope">
          {{
            scope.row.relatedTagValue
              ? scope.row.relatedTagValue
              : scope.row.relatedUserFieldNote
              ? scope.row.relatedUserFieldNote
              : '/'
          }}
        </template>
      </el-table-column>
    </el-table>

    <div class="bottom-area">
      <el-pagination
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout=" prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getIssueList } from '@/api/questionnaire'

export default {
  props: {
    questionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [
        {
          value: 0,
          label: '单选题',
        },
        {
          value: 1,
          label: '多选题',
        },
        {
          value: 2,
          label: '日期编辑题',
        },
        {
          value: 3,
          label: '填空题',
        },
        {
          value: 4,
          label: '图片编辑题',
        },
      ],
      options2: [
        { value: 0, label: '自定义' },
        { value: 1, label: '数据字典' },
        { value: 2, label: '标签库' },
        { value: 3, label: '拍摄单品' },
      ],
      searchForm: {
        questionType: '',
        name: '',
        content: '',
        type: '',
      },
      tableList: [],
      selectList: [],
      currentPage: 1,
      selectAll: '0',
      pageSize: 10,
      total: 0,
    }
  },
  watch: {
    questionList: {
      handler(val) {
        console.log(val)
        this.selectList = JSON.parse(JSON.stringify(val)) || []
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    defaultSelection() {
      this.$nextTick(() => {
        // 默认选中
        console.log(this.selectionData)
        this.tableList.forEach((e, i) => {
          this.selectList.forEach((row) => {
            if (e.id === row.id) {
              this.$refs.dataTable.toggleRowSelection(this.tableList[i], true)
            }
          })
        })
      })
    },
    /**
     * 手动处理每条数据是否选中
     * 否则在分页切换后selection是空，导致前面一页已选数据被清空
     */
    onSelectData(selection, row) {
      console.log('执行单条数据处理')

      // 判断this. selectionData
      if (this.selectList.find((e) => e.id === row.id)) {
        // 存在，则删除row这条数据
        this.selectList = this.selectList.filter((e) => e.id !== row.id)
      } else {
        this.selectList.push({
          ...row,
        })
        console.log(this.selectList)
      }
    },
    onSelectDataAll(selection) {
      // 根据selection是否有数据判断，是全选/取消全选
      if (selection.length) {
        // 全选
        const data = []
        selection.forEach((e) => {
          // 全选数据 ---- 已选数据
          const hove = this.selectList.find((f) => e.id === f.id)
          // 如果selection中存在未选中的数据，则将其加入到data中
          console.log('全选加入', e)

          !hove && data.push({ ...e })
        })
        this.selectList = [...this.selectList, ...data]
      } else {
        // 取消全选
        this.selectList = this.selectList.filter((e) => {
          // 当前页的数据 ----- 已选中数据
          const hove = this.tableList.find((f) => e.id === f.id)
          // 如果this.selectList存在hove这条数据，则删除
          return !hove
        })
      }
    },
    async handleSizeChange(val) {
      this.pageSize = val
      await this.getList()
    },
    async handleCurrentChange(val) {
      this.currentPage = val
      await this.getList()
    },
    async getList() {
      const reqObj = {
        data: {
          problemNoOrName: this.searchForm.name,
          type: this.searchForm.questionType,
          problemType: this.searchForm.type,
        },
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
      }

      const res = await getIssueList(reqObj)

      if (res.success) {
        this.tableList = res.data.content
        this.total = Number(res.data.total)
      }
      this.$nextTick(() => {
        console.log(this.$refs)
        this.$refs.dataTable.clearSelection()
      })
      this.defaultSelection()
    },
    async search() {
      await this.getList()
    },
    async reset() {
      Object.assign(this._data.searchForm, this.$options.data().searchForm)
      await this.getList()
    },
  },
}
</script>

<style lang="scss" scoped>
.checkQuestion {
  .search-area {
    display: flex;
    flex-wrap: wrap;
    .item {
      white-space: nowrap;
      margin-right: 20px;
      margin-bottom: 10px;
    }
    .button-item {
      white-space: nowrap;
      margin-bottom: 10px;
    }
  }

  .bottom-area {
    display: flex;
    justify-content: flex-end;

    .selectAllRadio {
      pointer-events: none;
    }
  }
}
</style>
