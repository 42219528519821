import request from "@/utils/request";

export function getQuestionnaireList(data) {
  return request({
    url: "/marketing-web-api/admin/questionnaire/pageList",
    method: "POST",
    data,
  });
}

export function getTemplateList(data) {
  return request({
    url: "/marketing-web-api/admin/questionnaire/template/pageList",
    method: "POST",
    data,
  });
}

export function getIssueList(data) {
  return request({
    url: "/marketing-web-api/admin/questionnaire/problem/pageList",
    method: "POST",
    data,
  });
}

export function deleteByIds(data) {
  return request({
    url: "/marketing-web-api/admin/questionnaire/problem/deleteByIds",
    method: "POST",
    data,
  });
}

export function getTagCategoryList(data) {
  return request({
    url: "/tag-web-api/admin/tag/getTagCategoryList",
    method: "POST",
    data,
  });
}

export function parentDictionarys(data) {
  return request({
    url: "/misc-web-api/admin/dictionary/parentDictionarys",
    method: "POST",
    data,
  });
}

export function getDictList(data) {
  return request({
    url: "/misc-web-api/admin/dictionary/list",
    method: "POST",
    data,
  });
}

export function getCustomerData(data) {
  return request({
    url: "/marketing-web-api/api/customerData/list",
    method: "POST",
    data,
  });
}

export function sampleCategory(data) {
  return request({
    url: "/product-web-api/admin/sampleCategory/list",
    method: "POST",
    data,
  });
}

export function sampleList(data) {
  return request({
    url: "/product-web-api/admin/sample/list",
    method: "POST",
    data,
  });
}

export function sampleDetail(data) {
  return request({
    url: "/product-web-api/admin/sample/queryById",
    method: "POST",
    data,
  });
}

export function addQuestionnaire(data) {
  return request({
    url: "/marketing-web-api/admin/questionnaire/problem/save",
    method: "POST",
    data,
  });
}

export function getIssueDetail(data) {
  return request({
    url: "/marketing-web-api/admin/questionnaire/problem/detail",
    method: "POST",
    data,
  });
}

export function saveTemplate(data) {
  return request({
    url: "/marketing-web-api/admin/questionnaire/template/save",
    method: "POST",
    data,
  });
}

export function saveQuestionnaire(data) {
  return request({
    url: "/marketing-web-api/admin/questionnaire/save",
    method: "POST",
    data,
  });
}

export function getQuestionnaireById(data) {
  return request({
    url: "/marketing-web-api/admin/questionnaire/detailOfBase",
    method: "POST",
    data,
  });
}

export function getQuestionnaireSubmitList(data) {
  return request({
    url: "marketing-web-api/admin/questionnaire/detailOfSubmitPage",
    method: "POST",
    data,
  });
}

export function addServiceNo(data) {
  return request({
    url: "marketing-web-api/admin/questionnaire/addServiceNo",
    method: "POST",
    data,
  });
}

export function getTemplateDetail(data) {
  return request({
    url: "marketing-web-api/admin/questionnaire/template/detail",
    method: "POST",
    data,
  });
}

export function deleteTemplate(data) {
  return request({
    url: "marketing-web-api/admin/questionnaire/template/deleteByIds",
    method: "POST",
    data,
  });
}

export function getTitleQuestion(data) {
  return request({
    url: "marketing-web-api/admin/questionnaire/titleOfSubmitPage",
    method: "POST",
    data,
  });
}

export function getTagDetails(data) {
  return request({
    url: "tag-web-api/admin/tag/getTagDetails",
    method: "POST",
    data,
  });
}
