<template>
  <div class="addQuestionnaire">
    <div class="title">
      <ty-item-title
        title="新增模板"
        :margin-left="true"
        :save-button="true"
        :cancel-button="true"
        @save="save"
        @cancel="cancel"
      >
      </ty-item-title>
    </div>

    <div>
      <el-form
        ref="dataForm"
        label-width="140px"
        label-position="right"
        :rules="rules"
        :model="form"
        class="content-container"
      >
        <div class="left">
          <el-form-item label="模板名称：" prop="templateName">
            <el-input
              v-model="form.templateName"
              style="width: 210px"
              placeholder="请输入模板名称"
              show-word-limit
              maxlength="10"
            ></el-input>
          </el-form-item>

          <el-form-item label="开头语：" prop="startWord">
            <el-input
              v-model="form.startWord"
              type="textarea"
              placeholder="请输入问卷开头语"
              maxlength="200"
              show-word-limit
              :rows="8"
            ></el-input>
          </el-form-item>
        </div>

        <div class="right">
          <el-form-item label="问题：" prop="questionList">
            <div class="question-list">
              <div class="list-item">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-plus"
                  size="mini"
                  style="background: #e9effc; flex: 1; height: 40px"
                  @click="choseQuestion"
                  >选择问题</el-button
                >

                <div class="delete"></div>
              </div>

              <div v-if="form.questionList.length" class="question-area">
                <div v-for="(item, index) in form.questionList" :key="index" class="list-item">
                  <div class="question-item" :class="currentQuestion == index ? 'item-shadow' : ''">
                    <span style="font-size: 14px; margin-right: 50px; white-space: nowrap">
                      <span class="index">{{ index + 1 }}.</span>
                      <span class="question-name">&nbsp;&nbsp;{{ item.content }}</span>
                    </span>

                    <span style="font-size: 12px; white-space: nowrap">
                      <span class="tag orange-tag">{{
                        item.problemType == '0'
                          ? '自定义'
                          : item.problemType == '1'
                          ? '数据字典'
                          : item.problemType == '2'
                          ? '标签库'
                          : '拍摄产品'
                      }}</span>

                      <span class="tag blue-tag">{{
                        item.type == '0'
                          ? '单选题'
                          : item.type == '1'
                          ? '多选题'
                          : item.problemType == '2'
                          ? '日起编辑题'
                          : '填空题'
                      }}</span>
                    </span>
                  </div>

                  <div class="delete">
                    <i
                      class="el-icon-delete delete-icon"
                      :class="currentQuestion == index ? 'red-icon' : ''"
                      @click="deleteQuestion(index)"
                      @mouseover="currentQuestion = index"
                      @mouseleave="currentQuestion = null"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <el-dialog
      v-if="choseQuestionVisible"
      :visible.sync="choseQuestionVisible"
      title="选择问题"
      width="60%"
      class="questionDialog"
      :destroy-on-close="true"
    >
      <choseQuestion ref="question" :question-list="form.questionList"></choseQuestion>
      <span slot="footer">
        <el-button size="mini" @click="choseQuestionVisible = false">取 消</el-button>
        <el-button type="primary" size="mini" @click="questionOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tyItemTitle from '@/components/ty-item-title/index.vue'
import choseQuestion from '../addQuestionnaire/choseQuestion.vue'
import { saveTemplate, getTemplateDetail } from '@/api/questionnaire'

export default {
  components: {
    tyItemTitle,
    choseQuestion,
  },
  data() {
    return {
      choseQuestionVisible: false,
      currentQuestion: null,
      productList: [],
      productVisible: false,
      form: {
        id: '',
        templateName: '',
        startWord: '',
        questionList: [],
      },
      rules: {
        templateName: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        startWord: [{ required: true, message: '请输入开头语', trigger: 'blur' }],
        questionList: [{ required: true, message: '请选择问题', trigger: 'blur' }],
      },
    }
  },
  async mounted() {
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id

      const res = await getTemplateDetail({ data: this.$route.query.id })
      if (res.success) {
        const { templateName, openingWords, problems } = res.data
        this.form.templateName = templateName
        this.form.startWord = openingWords
        this.form.questionList = problems
      }
    }
  },
  methods: {
    choseQuestion() {
      this.choseQuestionVisible = true
    },

    deleteProduct(index) {
      this.productList.splice(index, 1)
    },
    deleteQuestion(index) {
      this.form.questionList.splice(index, 1)
    },
    choseProduct() {
      this.productVisible = true
    },
    productCancel() {
      this.productVisible = false
    },
    productOk(selectList) {
      this.productList = [...selectList]
      this.productVisible = false
    },
    async save() {
      this.$refs.dataForm.validate(async (valid) => {
        if (!valid) return

        const reqObj = {
          data: {
            id: this.form.id,
            templateName: this.form.templateName,
            openingWords: this.form.startWord,
            problems: this.form.questionList.map((el) => ({ id: el.id })),
          },
        }

        const res = await saveTemplate(reqObj)
        if (res.success) {
          this.$message.success(this.$route.query.id ? '保存成功' : '创建成功')
          this.$router.push({
            path: '/questionnaireManage/index',
            query: {
              listType: 'template',
            },
          })
        }
      })
    },
    finish() {},
    questionOk() {
      if (!this.$refs.question.selectList.length) {
        this.$message.error('至少选择一个问题')
        return
      }

      this.form.questionList = this.$refs.question.selectList
      this.$refs.dataForm.validateField('questionList')
      this.choseQuestionVisible = false
    },
    cancel() {
      this.$router.push({
        path: '/questionnaireManage/index',
        query: { listType: 'template' },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.addQuestionnaire {
  background: #ffffff;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .content-container {
    display: flex;
    height: 100vh;

    .left {
      flex: 1;
      padding-right: 60px;
      border-right: 1px solid #e0e0e0;
      box-sizing: border-box;
      overflow-y: auto;
      height: 100%;
      padding-top: 24px;

      .choseTemplate {
        display: inline-block;
        width: 100%;
        background: #fafafa;
        border: 1px solid #ededed;
        padding: 20px;
        display: flex;

        .please-chose {
          padding-left: 10px;
          border: 1px solid #e0e0e0;
          display: flex;
          justify-content: space-between;
          flex: 1;
          border-radius: 4px;
          cursor: pointer;

          .change {
            padding: 0px 25px;
            background: #f5f5f5;
            color: #2861e2;
            font-size: 14px;
            border-left: 1px solid #e0e0e0;
            box-sizing: border-box;
          }
        }
      }

      .product-area {
        .product-item {
          margin-right: 10px;
        }
      }
    }

    .right {
      flex: 1;
      height: 100%;
      padding-top: 24px;

      .text-button {
        cursor: pointer;
        color: #e86247;
      }

      .question-list {
        display: flex;
        flex-direction: column;
        padding-top: 5px;

        .list-item {
          margin-bottom: 16px;
          display: flex;
        }

        .question-area {
          display: flex;
          flex-direction: column;
          height: 60vh;
          overflow-y: auto;
        }

        .question-area::-webkit-scrollbar {
          width: 6px;
        }

        .question-area::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background: #e0e3e7;
        }

        .question-item {
          border: 1px solid #eaeaea;
          border-radius: 4px;
          padding: 0 16px;
          display: flex;
          justify-content: space-between;
          flex: 1;

          .index {
            color: #a4a8ac;
          }

          .tag {
            padding: 4px 6px;
            margin-right: 6px;
            white-space: nowrap;
          }

          .tag:last-child {
            margin-right: 0;
          }

          .orange-tag {
            background: rgba(255, 132, 38, 0.08);
            color: #f17d25;
          }

          .blue-tag {
            background: rgba(38, 144, 255, 0.08);
            color: #2690ff;
          }
        }

        .item-shadow {
          box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.08);
        }

        .delete {
          width: 44px;
          display: flex;
          justify-content: center;
          align-items: center;

          .delete-icon {
            cursor: pointer;
          }

          .red-icon {
            color: #e86247;
          }
        }
      }
    }
  }

  .questionDialog {
    ::v-deep .el-dialog__footer {
      border-top: 1px solid #ededed !important;
    }
  }

  ::v-deep .el-button--primary.is-plain {
    color: #2861e2;
    border: 1px solid #2861e2;
    background: #fff;
  }

  ::v-deep .el-dialog__header {
    background: #fff;
    border-bottom: 1px solid #ededed;
    border-radius: 6px 6px 0 0;
  }

  ::v-deep .el-dialog__body {
    // border-radius: 0 0 6px 6px;
    padding: 20px;
  }

  ::v-deep .el-dialog {
    border-radius: 6px;
  }
}
</style>
